const MESSAGES = {
  "1000": {
    "pt_br": "Campo inválido.",
  },
  "2000": {
    "pt_br": "Email inválido.",
  },
  "2001": {
    "pt_br": "O email informado é igual ao email atual.",
  },
  "2002": {
    "pt_br": "E-mail obrigatório.",
  },
  "3000": {
    "pt_br": "Telefone inválido! Verifique a quantidade de dígitos.",
  },
  "3001": {
    "pt_br": "O número de telefone deve ter 11 dígitos.",
  },
  "3002": {
    "pt_br": "O número do celular deve ter 11 dígitos.",
  },
  "3003": {
    "pt_br": "O número do celular deve começar com o dígito 9.",
  },
  "3004": {
    "pt_br": "O telefone informado é igual ao telefone atual.",
  },
  "3005": {
    "pt_br": "Número de celular obrigatório.",
  },
  "4000": {
    "pt_br": "Nome inválido.",
  },
  "4001": {
    "pt_br": "O nome deve ter no mínimo 2 caracteres.",
  },
  "5000": {
    "pt_br": "Senha inválida.",
  },
  "5001": {
    "pt_br": "A senha deve ter no mínimo 8 caracteres.",
  },
  "5002": {
    "pt_br": "As senhas não coincidem.",
  },
  "6000": {
    "pt_br": "Nome de usuário inválido.",
  },
  "6001": {
    "pt_br": "O nome de usuário deve conter entre 5 e 20 caracteres.",
  },
  "6002": {
    "pt_br": "O nome de usuário deve conter ao menos 3 letras.",
  },
  "6003": {
    "pt_br": "O nome de usuário deve começar e terminar com letras ou números.",
  },
  "6004": {
    "pt_br": "O nome de usuário deve conter somente letras, números, ponto(.), underline(_) e hífen(-).",
  },
  "6005": {
    "pt_br": "O nome de usuário não está disponível.",
  },
  "6006": {
    "pt_br": "Que pena! Esse nome de usuário já existe.",
  },
  "7000": {
    "pt_br": "Url inválida.",
  },
  "7001": {
    "pt_br": "A url deve conter entre 5 e 20 caracteres.",
  },
  "7002": {
    "pt_br": "A url deve conter ao menos 3 letras.",
  },
  "7003": {
    "pt_br": "A url deve conter somente letras e números.",
  },
  "7004": {
    "pt_br": "Que pena! Essa url já está em uso.",
  },
  "7005": {
    "pt_br": "Ocorreu um erro na verificação da URL da sua loja.",
  },
  "8000": {
    "pt_br": "Código inválido.",
  },
  "8001": {
    "pt_br": "O código deve ter 6 dígitos.",
  },
  "8002": {
    "pt_br": "Não foi possível enviar o código de verificação.",
  },
  "8003": {
    "pt_br": "Chave de acesso inválida.",
  },
  "9000": {
    "pt_br": "Data inválida.",
  },
  "9001": {
    "pt_br": "Dia inválido.",
  },
  "9002": {
    "pt_br": "Mês inválido.",
  },
  "9003": {
    "pt_br": "Ano inválido.",
  },
  "10000": {
    "pt_br": "Você deve aceitar os termos de uso para continuar.",
  }
}

function getMessage(code, language = "pt_br") {
  const message = MESSAGES[code];

  return message && message[language] ? message[language] : MESSAGES["1000"]["pt_br"];
}

export default { getMessage, MESSAGES }