/** tempo expiração */
const expiration = 6
/********************/

// eslint-disable-next-line
Date.prototype.addHours = function (h) {
  this.setHours(this.getHours() + h)
  return this
}

// eslint-disable-next-line
Date.prototype.removeHours = function (h) {
  this.setHours(this.getHours() - h)
  return this
}

// eslint-disable-next-line
Date.prototype.addMinutes = function (m) {
  this.setMinutes(this.getMinutes() + m)
  return this
}

// eslint-disable-next-line
Date.prototype.removeMinutes = function (m) {
  this.setMinutes(this.getMinutes() - m)
  return this
}

function encode (value, exp) {
  let timestamp = 0

  if (exp) {
    const date = new Date()
    timestamp = date.getTime()
  }

  if (typeof value === 'boolean') {
    return '__exp|' + timestamp + '|__cs_bool|' + (value ? '1' : '0')
  }

  if (typeof value === 'string') {
    return '__exp|' + timestamp + '|__cs_strn|' + value
  }

  if (typeof value === 'function') {
    return '__exp|' + timestamp + '|__cs_strn|' + value.toString()
  }

  if (value === Object(value)) {
    return '__exp|' + timestamp + '|__cs_objt|' + JSON.stringify(value)
  }

  return value
}

function decode (value) {
  let type, length, source

  length = value.length
  if (length < 9) {
    return value
  }

  const spl = value.split('|__cs_')

  type = spl[1].substr(0, 4)
  source = spl[1].substring(5)

  switch (type) {
    case 'date':
      return new Date(source)

    case 'expr':
      return new RegExp(source)

    case 'numb':
      return Number(source)

    case 'bool':
      return Boolean(source === '1')

    case 'strn':
      return '' + source

    case 'objt':
      return JSON.parse(source)

    default:
      return value
  }
}

function getStorage () {
  const storage = window.localStorage
  const get = key => {
    const item = storage.getItem(key)

    if (!item) {
      return null
    }

    const spl = item.split('|__cs_')
    if (!spl[1]) {
      storage.removeItem(key)
      return
    }

    if (parseInt(spl[0].substring(6)) > 0) {
      const exp = new Date(parseInt(spl[0].substring(6))).addHours(expiration).getTime()
      const date = new Date()
      const currentTime = date.getTime()

      if (currentTime > exp) {
        storage.removeItem(key)
        return null
      }
    }

    return decode(item)
  }

  return {
    set: (key, value, exp = false) => {
      storage.setItem(key, encode(value, exp))
    },
    get: {
      item: get
    },
    remove: (key) => {
      storage.removeItem(key)
    },
    clear: () => {
      storage.clear()
    }
  }
}

export default function customStorage () {
  return getStorage()
}
