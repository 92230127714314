export default class DesktopBridge {

    static isDesktop() {
        return !!window.isElectron
    }

    static emit(func, data = null) {
        if(DesktopBridge.isDesktop()) {
            window.ipcRenderer.send(func, data)
        }
    }

    static listen(func, callback) {
        if(DesktopBridge.isDesktop()) {
            window.ipcRenderer.on(func, (event, msg) => callback(msg) )
        }
    }
}
