export default class {
  _code = null
  _data = null
  _errors = []

  constructor (data, code, errors) {
    this._code = code || 200
    this._data = data || null
    this._errors = errors || []
  }

  setCode (code) {
    this._code = code
  }

  getCode (code) {
    return this._code || code
  }

  setData (data) {
    this._data = data
  }

  getData (data) {
    return this._data || data
  }

  setErrors (errors) {
    this._errors = errors
  }

  getErrors (errors) {
    return this._errors || errors
  }

  toJSON () {
    let response = {
      'code': this._code
    }

    if (this._data) {
      response.data = this._data
    }

    if (this._errors) {
      response.errors = this._errors
    }

    return {
      'response': response
    }
  }
}
