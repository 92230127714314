import vueStore from '../store'
import vueRouter from '../router'

export default class AppBridge {

    static isApp() {
        return !!window.ReactNativeWebView;
    }

    static emit(type, data) {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ type, data }));
        }
    }

    static listen(event, callback) {
        if (window.ReactNativeWebView) {
            document.addEventListener('message', function (e) {
                let message = JSON.parse(e.data)
                if(message.type == event) {
                    callback(message);
                }
            });
        }
    }

	static lockLandscape() {
		if (this.isApp()) {
			emit('onLockOrientation');
		}
	}

	static unLockLandscape() {
		if (this.isApp()) {
			emit('onUnlockOrientation');
		}
	}
}