import Utils from '@/utils'
import store from '@/store'
const customStorage = require('./customStorage').default;

export function setXUserKey (xUserKey) {
  if (!Utils.empty(xUserKey)) {
    customStorage().set('konv.account.x-user-key', xUserKey, false)
    // store.dispatch('account/getUser')
    // store.dispatch('account/getUserAddresses')
  } else {
    customStorage().remove('konv.account.x-user-key')
    customStorage().remove('konv.account.oUser')
    store.dispatch('account/getUser', null)
  }
  store.dispatch('account/getIsLogged')
}

export function getXUserKey (xUserKey) {
  return typeof(customStorage) != "undefined" ? customStorage().get.item('konv.account.x-user-key', false) : null
}

export function setXAPIEnv (xApiEnv) {
  if (!Utils.empty(xApiEnv)) {
    customStorage().set('konv.account.x-api-env', xApiEnv, false)
  } else {
    customStorage().remove('konv.account.x-api-env')
  }
}

export function getXAPIEnv () {
  return customStorage().get.item('konv.account.x-api-env')
}
