import QRCodeLib from "qrcode";
import AppBridge from '@/plugins/app';

export class QRCode {
    static async getDataUrl(text) {
        return new Promise((resolve) => {
            QRCodeLib.toDataURL(text, { errorCorrectionLevel: 'H' }, function (err, url) {
                resolve(url);
            })
            // resolve(QRCodeLib.toDataURL(text, "H"));
        })
    }

    static async downloadQRCodeImage(filename, text) {
        const dataURL = await QRCode.getDataUrl(text);
        if (AppBridge.isApp()) {
            AppBridge.emit('downloadQrCode', {dataURL, filename});
        } else {
            const a = document.createElement('a');
            a.setAttribute("href", dataURL);
            a.setAttribute("download", filename);
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }
}