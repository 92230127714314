/* Formata informações da api address/list */
export function formatCities (aCities) {
  let aInfosRes = aCities
  let aInfos = {
    uf: {},
    cities: {},
    districts: {}
  }

  for (let cityKey in aInfosRes) {
    let oInfo = aInfosRes[cityKey]

    /* Formatar Estados */
    aInfos.uf[oInfo.city_uf] = oInfo.city_uf

    let oTempCity = {}

    oTempCity[oInfo.city_id] = oInfo.city_name

    if (!aInfos.cities[oInfo.city_uf]) {
      aInfos.cities[oInfo.city_uf] = {}
    }

    /* Formatar Cidades */
    Object.assign(aInfos.cities[oInfo.city_uf], oTempCity)

    let oTempDistrict = {}
    /* Formatar Bairros */
    for (let districtKey in oInfo.districts) {
      let oDistrict = oInfo.districts[districtKey]

      oTempDistrict[oDistrict.district_id] = oDistrict.district_name
    }
    aInfos.districts[oInfo.city_id] = oTempDistrict
  }
  return aInfos
}
