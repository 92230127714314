export default class Location {
    id = null
    address = null
    number = null
    zipcode = null
    complement = null
    district = null
    city = null
    state = null
    country = null
    observation = null
    geolocation = null

    constructor(address, number, zipcode, complement, district, city, state, country, observation, geolocation) {
        this.address = address
        this.number = number
        this.zipcode = zipcode
        this.complement = complement
        this.district = district
        this.city = city
        this.state = state
        this.country = country
        this.observation = observation
        this.geolocation = geolocation
    }

    toAddressString(inverseNumber = false) {
        let location = this;
        let addressString = `${location.address}${location.number ? `, ${location.number}`: ''}`;

        if(inverseNumber) {
            addressString = `${location.number ? `${location.number}, `: ''}${location.address}`;
        }

        if(location.region || location.city) {
            addressString += ` - ${location.region ? `${location.region}${location.city ? `, ${location.city}` : ''}`: `${location.city}`}`;
        }
        
        if(location.state || location.country) {
            addressString += ` - ${location.state ? `${location.state}${location.country ? `, ${location.country}` : ''}`: `${location.country}`}`;
        }

        if(location.zipcode) {
            addressString += ` - ${location.zipcode}`;
        }

        return addressString;
    }
    static fromObject(obj) {
        let location = new Location;

        Object.keys(obj).map((key) => {
            switch(key) {
                case 'id':
                case 'address':
                case 'number': 
                case 'zipcode':
                case 'complement':
                case 'city':
                case 'state':
                case 'country': 
                case 'observation':
                    location[key] = obj[key];
                    break;
                case 'region':
                case 'district':
                    location.district = obj[key];
                    break;
                case 'geolocation':
                    location.geolocation = {
                        latitude: obj.geolocation.latitude,
                        longitude: obj.geolocation.longitude
                    };
                    break;
            }
        })

        return location;
    }
}