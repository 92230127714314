export const onBoardProgress = {
  // Informações da loja
  STEP_1_INCOMPLETE: 1,
  STEP_1_DONE: 2,

  // Localização da loja
  STEP_2_DONE: 4,

  // Regiões de entrega
  STEP_3_INCOMPLETE: 8,
  STEP_3_DONE: 16,

  // Horário de atendimento
  STEP_4_DONE: 32,

  // Formas de pagamento
  STEP_5_DONE: 64,

  // Mudança de rota
  STEP_6_DONE: 128,
};

/* 
usado somente para considerar como completa a primeira fase do onboard que 
contempla informações da loja, localização, regiões de entrega e formas de pagamento
*/
export function getDone(progress) {
  const DONE =
    onBoardProgress.STEP_1_DONE |
    onBoardProgress.STEP_2_DONE |
    onBoardProgress.STEP_3_DONE |
    onBoardProgress.STEP_4_DONE |
    onBoardProgress.STEP_5_DONE |
    onBoardProgress.STEP_6_DONE;
  return (progress & DONE) == DONE;
}

/* verifica progresso na fase inicial do onboard */
export function verifyProgress(configurations) {
  if (!configurations) return 0;

  let progress = 0;
  // info
  if (
    configurations.basic_info &&
    configurations.basic_info.name &&
    configurations.basic_info.categories.length
  ) {
    progress = onBoardProgress.STEP_1_DONE;
  } else if (
    configurations.basic_info &&
    (configurations.basic_info.name ||
      configurations.basic_info.categories.length ||
      configurations.basic_info.email ||
      configurations.basic_info.phone1 ||
      configurations.basic_info.phone2 ||
      configurations.basic_info.description)
  ) {
    progress = onBoardProgress.STEP_1_INCOMPLETE;
  }

  // location
  if (configurations.location && configurations.location.address) {
    progress |= onBoardProgress.STEP_2_DONE;
  }

  // regions
  if (
    configurations.delivery_region &&
    configurations.delivery_region.length &&
    configurations.delivery_region[0].up_to !== 0
  ) {
    progress |= onBoardProgress.STEP_3_DONE;
  } else if (
    configurations.delivery_region &&
    configurations.delivery_region.length &&
    (configurations.delivery_region[0].free_fee ||
      configurations.delivery_region[0].min_order !== '0.00' ||
      configurations.delivery_region[0].old_price !== 0 ||
      configurations.delivery_region[0].price !== '0.00' ||
      configurations.delivery_region[0].minDelivery !== 0 ||
      configurations.delivery_region[0].maxDelivery !== 0)
  ) {
    progress |= onBoardProgress.STEP_3_INCOMPLETE;
  }

  if (configurations.working_time && configurations.working_time.length) {
    const hasTime = configurations.working_time.find((period) => {
      return period.days.find(
        (day) => !!day.open && !!day.close && !!day.active
      );
    });

    if (hasTime) {
      progress |= onBoardProgress.STEP_4_DONE;
    }
  }

  // payment
  if (configurations.payment_methods && configurations.payment_methods.length) {
    progress |= onBoardProgress.STEP_5_DONE;
  }

  return progress;
}

/* 
usado somente para considerar como completa a primeira fase do onboard que 
contempla informações da loja, localização, regiões de entrega e formas de pagamento
*/
export function getDonePublish(progress) {
  const DONE =
    onBoardProgress.STEP_1_DONE |
    onBoardProgress.STEP_2_DONE |
    onBoardProgress.STEP_3_DONE |
    onBoardProgress.STEP_4_DONE |
    onBoardProgress.STEP_5_DONE;
  return (progress & DONE) == DONE;
}

/* verifica progresso para publicar loja */
export function verifyToPublish(configurations) {
  if (!configurations) return 0;

  const progress = verifyProgress(configurations);

  return progress;
}
