import dayjs from 'dayjs';

const CAMPAIGN_STATUSES = {
  DRAFT: 1,
  EXPIRED_DRAFT: 2,
  UNDER_ANALYSIS: 4,
  ARCHIVED: 8,
  ACTIVE: 16,
  SCHEDULED: 32,
  PROCESSING: 64,
  FINISHED: 128,
  PAUSED: 256,
};

export default class CheckCampaign {
  constructor(campaign) {
    this.campaign = campaign;
  }

  hasExpiredDraft() {
    if (
      this.campaign.type === 'PN' &&
      this.campaign.status === CAMPAIGN_STATUSES.DRAFT
    ) {
      const hasSendTime =
        this.campaign.configs && this.campaign.configs.send_time;
      if (hasSendTime) {
        let { date } = this.campaign.configs.send_time;
        const { time } = this.campaign.configs.send_time;

        if (!date) {
          date = dayjs(this.campaign.created_at).format('YYYY-MM-DD');
        }

        const expiration = `${date} ${time}:00`;
        const currentDate = dayjs();
        const expired = dayjs(expiration).isBefore(currentDate);
        if (expired) {
          return true;
        }
      }
    }

    return false;
  }

  CampaignProcess = () => {
    if (this.hasExpiredDraft()) {
      this.campaign.status = CAMPAIGN_STATUSES.EXPIRED_DRAFT;
    }

    const counter = CAMPAIGN_STATUSES[this.campaign.status];
    const { configs } = this.InsertConfigsTypes(counter);

    this.campaign.config = configs || [];

    if (this.campaign.status === 'DRAFT') {
      if (this.campaign.creatives) {
        this.campaign.config.push('publish');
        this.campaign.config.push('details');
      } else {
        this.campaign.config.push('creative');
      }
    }

    return this.campaign;
  };

  InsertConfigsTypes = (value) => {
    const configs = [
      { id: CAMPAIGN_STATUSES.DRAFT, configs: ['edit'] },
      {
        id: CAMPAIGN_STATUSES.EXPIRED_DRAFT,
        configs: ['edit'],
      },
      {
        id: CAMPAIGN_STATUSES.UNDER_ANALYSIS,
        configs: ['creative', 'details', 'edit_block'],
      },
      { id: CAMPAIGN_STATUSES.ARCHIVED, configs: ['details'] },
      {
        id: CAMPAIGN_STATUSES.PROCESSING,
        configs: ['details', 'pause', 'archive'],
      },
      {
        id: CAMPAIGN_STATUSES.SCHEDULED,
        configs: ['details', 'pause', 'archive'],
      },
      {
        id: CAMPAIGN_STATUSES.ACTIVE,
        configs: ['details', 'pause', 'archive'],
      },
      { id: CAMPAIGN_STATUSES.FINISHED, configs: ['details', 'archive'] },
      {
        id: CAMPAIGN_STATUSES.PAUSED,
        configs: ['resume', 'archive'],
      },
    ];
    const configsTypes = (configs || []).find((item) => item.id === value);
    return configsTypes;
  };
}
